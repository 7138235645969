/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function() {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

  if ('serviceWorker' in navigator &&
      (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
    .then(function(registration) {
      // Check to see if there's an updated version of service-worker.js with
      // new files to cache:
      // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-registration-update-method
      if (typeof registration.update === 'function') {
        registration.update();
      }

      // updatefound is fired if service-worker.js changes.
      registration.onupdatefound = function() {
        // updatefound is also fired the very first time the SW is installed,
        // and there's no need to prompt for a reload at that point.
        // So check here to see if the page is already controlled,
        // i.e. whether there's an existing service worker.
        if (navigator.serviceWorker.controller) {
          // The updatefound event implies that registration.installing is set:
          // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
          var installingWorker = registration.installing;

          installingWorker.onstatechange = function() {
            switch (installingWorker.state) {
              case 'installed':
                // At this point, the old content will have been purged and the
                // fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in the page's interface.
                break;

              case 'redundant':
                throw new Error('The installing ' +
                                'service worker became redundant.');

              default:
                // Ignore
            }
          };
        }
      };
    }).catch(function(e) {
      console.error('Error during service worker registration:', e);
    });
  }
  // Your custom JavaScript goes here

  function setheight() {
    var windowH = $(window).height();
    $('.setheight').each(function(){
      if($(this).hasClass('mh')){
        var h = $('header').outerHeight();
        windowH = windowH - h;
      }
      $(this).css({
        'height': windowH
      })
    })
  }
  setheight();
  
   $('.burger-icon').click(function(e){
      e.preventDefault();
     $('body').toggleClass('open');
  })
   $('.dot-icon').click(function(e){
      e.preventDefault();
     $('body').toggleClass('open-right');
  })
   $('header').each(function(){
    var ct=0;
    var hh = $('header').offset().top;
    console.log(hh);
    $(window).scroll(function(){
      var top = $(window).scrollTop(),
          h = $(window).height(),
          header = $('header'),
          filter = $('.filter'),
          nf = filter.length;

      $('.cart').removeClass('in');
      if(!$('.nav-menu').hasClass('open')){
        if(top > hh){
          header.addClass('fixed');
          $('.cart').removeClass('in');
        }else{
          header.removeClass('fixed')
        }
        if(ct> top){
          header.addClass('sticky');
          if(nf>0){
            filter.removeClass('nf');
          }
        }else{
          header.removeClass('sticky');
          if(nf>0){
            filter.addClass('nf')
          }
        }

        if(top>h){
          jQuery('.back-to-top').removeClass('h');
        }else{
          jQuery('.back-to-top').addClass('h');
        }

        ct = top;
      }
    });
   })
   $('footer').find('h3').each(function(){
    $(this).click(function(){
      $(this).parent().toggleClass('open');

    })
   })

  function dropdown(){
    var w =$(window).width(),p = $('.dropdown > a, .my-account > a');
    if(w<768){
      p.click(function(e){
        e.preventDefault();
        p.parent().toggleClass('in');
      })
    }
  }
  dropdown();
    function slidermobile(){
    var w =$(window).width(),slider = $('.slider-xs');
    if(w<768){
      slider.addClass('owl-carousel').removeClass('ns');
      slider.owlCarousel({
          loop:false,
          dots:true,
          nav: false,
          responsive : {
            0 : {
                items: 1
            },
            768 : {
                items: 3
            },
          }
      })
    }else{
      slider.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
      slider.find('.owl-stage-outer').children().unwrap();
    }
  }
  slidermobile();
  $(window).resize(function(){
    dropdown();
    slidermobile();
  })
  $(document).ready(function(){
    $('.slider').each(function(){
      var t = $(this),
          item = t.attr('data-items') ? t.attr('data-items') : 1,
          navs = t.attr('data-nav') && t.attr('data-nav')=="no" ? false : true,
          ah = t.attr('data-autoheight') && t.attr('data-autoheight')=="yes" ? true : false,
          ap = t.attr('data-autoplay') && t.attr('data-autoplay')=="yes" ? true : false,
          speed = t.attr('data-time') ? t.attr('data-time') : 5000,
          dot = navs ? false : true,
          loops = dot ? true : false;
      t.addClass('owl-carousel').each(function(){
        var t = $(this);
          t.owlCarousel({
              loop: loops,
              dots: dot,
              nav: navs,
              navText : ["<span class='fa fa-angle-left'></span>","<span class='fa fa-angle-right'></span>"],
              autoplay: ap,
              autoplayTimeout: speed,
              autoplaySpeed: 800,
              responsive : {
                0 : {
                    items: 1
                },
                768 : {
                    items: 3
                },
                992 : {
                    items: item
                }
              }
          })
      })
    })

    $(".form-slider").each(function(){
    $(this).slider({
      tooltip: 'always',
      tooltip_position: 'bottom',
      tooltip_split: true,
      formatter: function(value) {
        var v = value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        return  "Rp. "+v;
      }
    });
  })
  })
    

  $('select').each(function(){
    $(this).addClass('select').selectpicker({
      style: 'select-control',
      size: 4
    });
  })
  /* */
  var  f = true, l = false;
  function product(){
    $('.product, .single').each(function(){
      $(this).find('.item').each(function(){
        var t = $(this),
          par = t.parent(),
          h = t.outerHeight()-80;
        par.height(h);
      });
      l = true;
      console.log('mari');
      lmore();

      $(this).find('.action .btn').click(function(e){
        e.preventDefault();
        var t = $(this),
            action = t.closest('.action');
        action.addClass('loading');
        setTimeout(function(){
            action.removeClass('loading').addClass('added');
            $.notify("Rayban Sunglass RB 2140 997-3F successfully added to your cart",{
              className: 'success',
              position: 't c'
            });
        },1000)

      });
      $(this).find('.action .count i.plus').click(function(){
          var value = $(this).siblings('.value'),
              span = $(this).siblings('span'),
              v = parseInt(value.val())+1;
          value.val(v);
          span.html(v);

      })
      $(this).find('.action .count i.minus').click(function(){
          var value = $(this).siblings('.value'),
              span = $(this).siblings('span'),
              v = parseInt(value.val())-1;
          v!=0 && (value.val(v),span.html(v));

      })

    })
  }
  product();
  var cs = 0;

  function lmore(){
    console.log('f :'+f);
    console.log('l :'+l);
    if( f && l){
        f = false;
        $('.product-page .items').jscroll({
          loadingHtml: '<div class="loading">Loading...</div>',
          padding: 20,
          nextSelector: 'a.lmore:last',
          contentSelector: '.items .col-sm-4,.lmore',
          autoTriggerUntil: 5,
          callback: function(){
            product();
          }
        });
    }
  }

  $('[data-toggle="tooltip"]').tooltip({
    placement: 'auto',
    container: 'body',
    trigger: 'hover'
  });

  $('.link-promo').click(function(e){
    var id = $(this).find('a').data('target');
    $(id).on('shown.bs.modal',function(event){
            $('.modal-backdrop').addClass('home');
        })
        .modal('show')
    e.preventDefault();
  })

  //shipping service 
  $('.list-ship input[type="radio"]').change(function(){
   
    if($(this).is(':checked')){

      var name = $(this).val(),
          form = $('.form-ship.'+name);
      
      form.slideDown('slow').siblings('.form-ship').slideUp('slow');

    }
  })

  //price packet
  $('select[name="packet"]').change(function(){
    var price = $(this).find("option:selected").data('price'),
        b = $(this).closest('.form-ship').find('.ship-cost').find('b'),
        num = price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
   
    b.text('Rp. '+num);
  })

  $('.filter').each(function(){
    var title = $('.collapse-filter h3'), t = $(this);

    title.click(function(){
      var par = $(this).parent(),
          ul = par.find('ul');
          par.addClass('animate');

      /*if(par.hasClass('open')){
        ul.slideUp('slow',function(){
          par.addClass('open');
        })
      }else{*/
        $('.filter .collapse-filter').each(function(){
          if(!$(this).hasClass('animate')){
            $(this).removeClass('open');
            $(this).find('ul').slideUp('slow');
          }
        })
        ul.slideToggle('slow',function(){
          par.toggleClass('open').removeClass('animate');
        })
      //}
    })

    $(this).find('.arrow').click(function(){
        t.toggleClass('open');
        $(document).click(function(event) {
           t.removeClass('open');
        });
        $('.filter').click(function(event){
          event.stopPropagation();
        });
    })

  });

  $('.account .sliders').each(function(){
    var image = $(this).find('.image').length;

    if(image>1){
      $(this).addClass('owl-carousel').owlCarousel({
        loop: true,
        dots: true,
        nav: false,
        navText : ["<span class='fa fa-angle-left'></span>","<span class='fa fa-angle-right'></span>"],
        autoplay: true,
        autoplayTimeout: 5000,
        items: 1
      })
    }
  })
  $('.cart > a').click(function(e){
    e.preventDefault();
    $(this).parent().toggleClass('in');
    $('.cart > a').click(function(e){
      e.stopPropagation();
    });
    $(document).on('click',function(e){
      if($(e.target).closest('.cart').length === 0) {
      $('.cart').removeClass('in');
      }
    })
   })

  // $('.bxslider').each(function(){
  //   $(this).bxSlider({
  //     pagerCustom: '.thumbnails',
  //     mode: 'fade',
  //     controls: false
  //   });
  // })

  $('.jqzoom').each(function(){
    $(this).jqzoom({
    zoomType: 'innerzoom',
    lens:true,
    preloadImages: false,
    alwaysOn:false,
    title: false,
  });
  })
  

  $('form.search').each(function(){
    var input = $(this).find('input.autocomplete');

    function patch() {

          var oldFn = $.ui.autocomplete.prototype._renderItem;

          $.ui.autocomplete.prototype._renderItem = function( ul, item) {
              var re = new RegExp("^" + this.term, "i") ;
              var t = item.label.replace(re,"<b>" + this.term + "</b>");
              return $( "<li></li>" )
                  .data( "item.autocomplete", item )
                  .append( "<a class='list-place'><i class='fa fa-map-marker'></i>" + t + "</a>" )
                  .appendTo( ul );
          };
      }
    patch();

    input.autocomplete({
        source: ['Jakarta Pusat','Jakarta Utara', 'Jakarta Barat', 'Jakarta Selatan', 'Jakarta Timur']
    })

  });
  $('.qty-box').each(function(){
    var t =$(this),
        input = t.find('.qty'),
        n = parseInt(input.val()),
        plus = t.find('.plus'),
        minus = t.find('.minus'),
        row = t.closest('tr'),
        net = row.find('input.net'),
        sub = row.find('input.subtotal'),
        textsub = row.find('.text-sub'),
        a;

    n = isNaN(n) ? 0 : n;
    plus.click(function(){
      n++;
      input.val(n);
      calculate(n);
    })
    minus.click(function(){
      if(n>1){
        n = n-1;
        input.val(n);
        calculate(n);
      }
    })

    input.keyup(function(){
      calculate($(this).val());
    })



    function calculate(i){
      var qsub = net.val()*i;
      textsub.text(currency(qsub));
      sub.val(qsub);
      calculateAll();
    }

    function currency(rp){
      rp = rp.toString();
      var v2 = parseInt(rp.replace(/\D/g,''),10).toLocaleString(),
          c = v2.replace(/,/g ,'.') == "NaN" ? "" : v2.replace(/,/g ,'.');
      
      return "Rp. "+c;
    }
    function calculateAll(){
      var table = t.closest('table.table'),
          subs = table.find('input.subtotal'),
          total = table.find('input.total'),
          textotal = table.find('.text-total'),
          q=0;

      console.log(subs.length);
      subs.each(function(){
        q+=parseInt($(this).val());
      })

      total.val(q);
      textotal.text(currency(q));
    }

  })
  $('.change-pass').click(function(e){
    e.preventDefault();
    var par = $(this).parent(),
        newPass = $('.new-pass');

    par.slideUp();
    newPass.slideDown('slow',function(){
      $(this).find('.form-control').eq(0).focus();
    });
  });
  var html = "<a class='back-to-top h'><i class='fa fa-angle-up'></i></a>";
      jQuery('body').append(html);
      jQuery('.back-to-top').on('click',function(){
        jQuery("html, body").animate({ scrollTop: 0 },'slow');
    });
})();
jQuery('img.svg').each(function(){
      var $img = jQuery(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');

      jQuery.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find('svg');

          // Add replaced image's ID to the new SVG
          if(typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
          }
          // Add replaced image's classes to the new SVG
          if(typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass+' replaced-svg');
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr('xmlns:a');

          // Replace image with new SVG
          $img.replaceWith($svg);

      }, 'xml');

  });